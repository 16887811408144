<template>
  <secondary-page-layout :title="$t('specialOffers')" :displayedNavigation="true">
    <div class="px-3">
      <div class="cards-grid">
<!--        <template v-if="!cardLoading">-->
<!--          <insurance-card v-for="product in insuranceType.products" :product="product" :key="product.id"/>-->
<!--        </template>-->
        <template v-if="!cardLoading">
          <promotion-card v-for="promotion in promotions" :product="promotion" :key="promotion.id"/>
        </template>
        <template v-else>
          <div class="image-card card" v-for="item in 2" :key="item">
            <div class="img skeleton-img skeleton"/>
            <div class="d-flex justify-content-between align-items-center p-3">
              <div class="w-50">
                <h3 class="title skeleton skeleton-text w-75"></h3>
                <h3 class="title skeleton skeleton-text w-50"></h3>
              </div>
              <div class="gray-btn skeleton w-50"></div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </secondary-page-layout>
</template>

<script>
import PromotionCard from "../components/PromotionCard";
export default {
  name: "Promotions",
  components: {PromotionCard},
  data() {
    return {
      promotions: null,
      cardLoading: true
    }
  },
  mounted() {
    this.getPromotions();
  },
  methods: {
    async getPromotions() {
      try {
        const {data} = await axios.get(`/promotions`);
        this.promotions = data;
        this.cardLoading = false;
      } catch (e) {
        throw e;
      }
    }
  }
}
</script>

<style scoped>
.skeleton-img {
  height: 175px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  margin-bottom: 12px;
}
</style>
