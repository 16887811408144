<template>
  <div class="image-card card">
    <router-link :to="{name: 'insurance.products', params: {id: product.product.id, form_component: product.product.form_component, }}">
      <img :src="product.cover" class="img"/>
    </router-link>
    <div class="card-body">
      <div class="me-1">
        <h3 class="title">{{ product.title }}</h3>
      </div>
      <button class="gray-btn" @click="showModal">{{$t('details')}}</button>
    </div>
  </div>
</template>

<script>
import PromotionModal from "./Modals/PromotionModal";

export default {
  name: "PromotionCard",
  props: {
    product: {
      type: Object
    }
  },
  methods: {
    showModal() {
      this.$modal.show(PromotionModal, {info: this.product}, this.$modalConfig,)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/sass/variables";
.image-card {
  overflow: hidden;
  .gray-btn {
    width: 200px;
  }
  .img {
    width: 100%;
    height: 175px;
    object-fit: cover;
    object-position: center;
  }
  .card-body {
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-weight: bold;
      font-size: 20px;
    }
    .success-text {
      font-weight: bold;
      font-size: 12px;
      color: $primary-color;
    }
  }
}
@media (max-width: 375px) {
  .image-card .card-body {
    .title {
      font-size: 14px;
    }
    .success-text {
      font-size: 10px;
    }
  }
}
</style>
